import React, { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BreakpointsContextProvider, Loading } from "@ixorigue/ixo-components";
import UserService from "./auth/UserService";

import "./index.css";

const App = React.lazy(() => import("./App"));

const renderApp = () => {
    const container = document.getElementById("root");
    if (container) {
        const root = createRoot(container);
        root.render(
            <StrictMode>
                <BreakpointsContextProvider>
                    <Suspense fallback={<Loading fullScreen />}>
                        <App />
                    </Suspense>
                </BreakpointsContextProvider>
            </StrictMode>
        );
    }
};

if (!window.navigator.onLine) {
    renderApp();
} else {
    UserService.initKeycloak()
        .then(() => renderApp())
        .catch(console.error);
}
